export const Style = (styled, Proto, Props) => {
return styled.div`

width: 100%;
height: 100%;
z-index: 100;
display: block;

.Chip_Detail_Landing {
position: relative;
width: 100%;
min-height: 100%;
height: 100%;
display: flex;
overflow: hidden;

  ._Poster {
  width: 45%;
  height: calc(100vh + 14px);
  display: flex;
  overflow: hidden;

  border-radius: 0 50% 50% 0;
  margin-top: -7px;
  margin-left: -7px;
  border: 7px solid rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;

    img {
    max-width: 100%;
    }

  }

  ._Main{
  width: 58%;
  padding: 2rem 16rem 2rem 8rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

    ._Navbar{
    display: flex;
    justify-content: flex-end;
    }

    ._Close{
    cursor: pointer;
    font-size: 26px;
    color: #777;

    &:hover {
    color: #999;
    }

    }

    ._Text {

      ._Title{
      font-size: 3.5rem;
      font-family: "Open Sans Bold";
      font-family: 'Ginora Sans Bold';
      margin: 3rem 0 2rem 0;

      // Color
      color: transparent;
      text-shadow: rgba(255,255,255,0.5) 0px 2px 2px;
      background: linear-gradient(to right, #1EEDDC 0%, #1AACCC 100%);
      background-clip: text;
      -webkit-background-clip: text;
      }

      ._Description{
      font-size: 1.8rem;
      text-align: justify;
      font-family: "Open Sans";
      color: #777;
      }

      ._Tags {
      display: inline-flex;
        
        ._Tag {
        color: #777;
        padding: 1rem 2rem;
        margin: 1rem;
        border-radius: 999px;
        border: 1px solid #ccc;
        }
      }
      
    }

    & > ._Footer {
    padding-top: 2rem;
    border-top: 1px solid #ccc;

      & > ._Title {
      margin: 1rem 0 2rem 0;
      font-size: 1.4rem;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.5);
      }

      img { 
      height: 7rem;
      }

    }

  }

  @media only screen and (max-width: 65rem) {
  
    ._Poster { 
    height: 80%; 
    margin-top: 5%;
    margin-bottom: 5%;
    }

  }

  @media only screen and (max-width: 55rem) {
    
    flex-direction: column;
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: auto;

    ._Poster { 
    width: 200px;
    height: 200px; 
    margin: 5% auto;

    border-radius: 50%;
    border: 7px solid rgba(0, 0, 0, 0.1);
    
      img { width: 100% !important;}
    } 

    ._Main { 
    flex: 1;
    width: 100%; 
    padding: 2rem 6rem;
    } 

  }

}

.Buttons {
display: flex;
margin-top: 4rem;
}

.Button_Play {
  width: 200px;
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(rgba(127, 255, 212, .84), rgba(0, 139, 139, .84) 50%);
  border-radius: 42px;
  border-width: 0;
  box-shadow: rgba(0, 139, 139, 0.24) 0 2px 2px, rgba(127, 255, 212, 0.4) 0 8px 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: .04em;
  line-height: 16px;
  margin: 0;
  margin-right: 2rem;
  padding: 18px 18px;
  text-align: center;
  text-decoration: none;
  text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px,rgba(255, 255, 255, 0.2) 0 0 12px,rgba(0, 139, 139, 0.6) 1px 1px 4px,rgba(0, 139, 139, 0.32) 4px 4px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;

  & > i {
  margin-right: 1rem;
  }

  &:hover {
  background-image: linear-gradient(#7FFFD4, #008B8B 50%);
  }

  &._Inactive {
  background-color: grey;
  background-image: linear-gradient(#ccc, #999 50%);
  text-shadow: none;
  box-shadow: none;
  }

}  

`;
}