//                                          ________________
// ....................................... /       MAIA.js |
/*██████████████████████████████████████████████████████████
██ 💠 MAIA ████████████ mandala interface for Initoris ███
████████████████████████████████████████████████████████████
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░/ _____ \░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░/ /     \ \░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░/ _____/       \_____ \░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░/ /     \       /     \ \░░░░░░░░░░░░░░░░██
██░░░░░░░░░/ _____/       \_____/       \_____ \░░░░░░░░░░██
██░░░░░░░░/ /     \       /     \       /     \ \░░░░░░░░░██
██░░░░░░░/ /       \_____/       \_____/       \ \░░░░░░░░██
██░░░░░░░\ \       /     \       /     \       / /░░░░░░░░██
██░░░░░░░░\ \_____/       \_____/       \_____/ /░░░░░░░░░██
██░░░░░░░░/ /     \       /     \       /     \ \░░░░░░░░░██
██░░░░░░░/ /       \_____/       \_____/       \ \░░░░░░░░██
██░░░░░░░\ \       /     \       /     \       / /░░░░░░░░██
██░░░░░░░░\ \_____/       \_____/       \_____/ /░░░░░░░░░██
██░░░░░░░░░\_____ \       /     \       / _____/░░░░░░░░░░██
██░░░░░░░░░░░░░░░\ \_____/       \_____/ /░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░\_____ \       / _____/░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░\ \_____/ /░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░\_______/░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
████████████████████████████████████████████████████████████
---------------------------------------------------------------------------*/

  import { MAIA_DATA } from './DATA/MAIA_DATA.js';
  import { MAIA_TREE } from './TREE/MAIA_TREE.js';
  import { MAIA_CORE } from './CORE/MAIA_CORE.js';

//---------------------------------------------------------------------------
//▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  export class MAIA { constructor (Props) {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------

  this.Props = Props;

  this.ENV = {
    URL: Public.ENV.URL,
    URI: Public.ENV.URL + `/Apps/Maia/Core`,
    ROUTE: Public.ENV.URL + `/apps/maia`,
  //----------------------------------------
    User: Props.ENV.User,
    Chip: Props.ENV.Chip,
    Scene: Props.ENV.Scene,
    Mode: Props.ENV.Mode,
  }

//---------------------------------------------------------------------------
  
  /*💾*/  this.DATA = new MAIA_DATA(this);
  /*🌳*/  this.TREE = new MAIA_TREE(this);
  /*🏵️*/  this.CORE = new MAIA_CORE(this);

//---------------------------------------------------------------------------

  this.Loop_BIND = this.LOOP.bind(this);
  this.Looping = true;

  this.Status = {};
  this.Loaded = false;
  this.Load();

//---------------------------------------------------------------------------
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  async Load () {
//---------------------------------------------------------------------------

  // Stop Load if MAIA.DATA.Load failed
  if(this.Status.Result === 'ERROR') {
    this.TREE.CLIENT.DOM.State.Log.Ref.style.visibility = 'visible';
    return false;
  }

  if(this.Loaded) { 
    this.LOOP(); 
    return true; 
  }
  
  if(this.DATA.Start() && this.CORE.Load()) {
    if(this.TREE.Load()) 
    { 
      this.CORE.Init(); 
      this.DATA.Init();
      this.TREE.Init(this.ENV.Mode);
      this.Loaded = true; 
    }
  }

  await new Promise(r => setTimeout(r, 100));
  return this.Load();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  Performance () {
//---------------------------------------------------------------------------
  
  const fps = this.CORE.TIME.framesLastSecond;
  const fpsThreshold = 55;

  if (fps < fpsThreshold) {
    console.log("FPS bajo:", fps);
    return false;
  }

  return true;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  async LOOP () {
//---------------------------------------------------------------------------

  if(!this.Loaded)  { console.log("Stop"); return false; }
  if(!this.Looping) { console.log("Stop"); return false; }

  // Frame
  var t = performance.now();
  this.CORE.TIME.Run();

  // CORE (Engine)
  this.CORE.Run();

  // TREE
  this.TREE.Run();

  // DEBUG
  this.CORE.DEBUG.Run();

  // Clean Client State
  this.TREE.CLIENT.Clean();

  // Last Frame.
  this.CORE.TIME.lastFrameTime = t;

  // requestAnimationFrame
  this.TREE.CLIENT.Window.requestAnimationFrame(this.Loop_BIND);

  // console.log("maia")

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//---------------------------------------------------------------------------
  // Reload () { this.ENV.Reload(); }
//---------------------------------------------------------------------------
  // t () { return this.CORE.TIME.currentSecond; } // ToDo: performance.now();
//---------------------------------------------------------------------------
  Stop_Loop () { this.Looping = false; }
//---------------------------------------------------------------------------
  Start_Loop () { if(!this.Looping) { this.Looping = true; this.LOOP(); } }
//---------------------------------------------------------------------------
  async Sleep (ms) { await new Promise(r => setTimeout(r, ms)); }
//---------------------------------------------------------------------------
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
}