//----------------------------------------------------------------------------------------
import { Style } from './Style.js';

export const Chip_Landing = Public.Libs.React.memo(({ 
  Item, 
  Images, 
  Open_Game, 
  Open_Trailer, 
  Part_Parallax,
  RouterDOM,
  html,
  Suspense
}) => {
  let URI = `${Public.ENV.URL}/Apps/Items/Chips/Detail`;
  const Styled = Public.Styles.Styled(Style, {});
  const history = RouterDOM.useHistory();

//████████████████████████████████████████████████████████████████████████████████████

  const Close = () => {
    history.goBack().goBack();
    Public.Scripts.Audio.Sound({ID: 'Pop_Close'});
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// URI_Poster=${Public.Scripts.Blob_To_Img_URL(Item?.Files[1]?.Blob)}

return html`
  <${Styled}>
    ${Item && html`
      <div className="Chip_Detail_Landing">
        <div className="_Poster">
          <${Suspense} fallback=${html`<div></div>`}>
            ${html`<${Part_Parallax} Props=${{Images: Images}}/>`}
          <//>
        </div>
        <div className="_Main">
          <div className="_Navbar">
            <${RouterDOM.Link} to="/start" onClick=${()=> { }}>
              <div className="_Close">
                <i className="icofont-close icofont-1x"></i>
              </div>
            <//>
          </div>
          <div className="_Content">
            <div className="_Text">
              <h2 className="_Title">${Item && Item.Title}</h2>
              <p className="_Description" dangerouslySetInnerHTML=${{ __html: Item?.Description }}></p>
              <!--<div className="_Tags">${Item?.Tags.map((Tag, i) => {
                return html`<div className="_Tag" key="Items_Chips_Detail_Tag_${i}">${Tag}</div>`
              })}
              </div>-->
            </div>
            <div className="Buttons">
              <${RouterDOM.Link} to="/maia" onClick=${()=> { }}>
                <div className="Button_Play" onClick=${()=> { Open_Game() }}>
                  <i className="icofont-game-controller icofont-1x"></i>Demo
                </div>
              <//>
              <div className="Button_Play _Inactive" onClick=${()=> { Open_Trailer() }}>
                <i className="icofont-ui-video-play icofont-1x"></i>Tráiler
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    `}
  <//>
`;
});

// <div className="Cover" style=${{"backgroundImage": `url(${Public.ENV.URL}/Public/Media/Database/Chips/${Item.ID}/${Cover})` }}>