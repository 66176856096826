import { Styled } from './Style/Style_App_User.js';
import { App_User_Detail } from '../User/Parts/Detail/App_User_Detail.js';

export const App_User = ({ Props }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/

  let URI = `${Public.ENV.URL}/Apps/Main/Landing`;
  const { useRef, useState, useEffect, useLayoutEffect } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();
  const history = RouterDOM.useHistory();

/*████████████████████████████████   🦉 SCRIPTS   ██████████████████████████████████*/

useLayoutEffect(() => {

    if (Public.TOKEN.Power === 'GUEST') {
      history.push('/login');
    } 
    else {
      // history.push('user/detail');
    }

  });

/*█████████████████████████████████   💻 RENDER   ███████████████████████████████████*/

return (html`
<${Style}>

  <${RouterDOM.Route} exact path=${["/user", "/user/detail"]}>
    <${App_User_Detail} Props=${Props} />
  <//>

<//>
`);
  
};