export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
background: antiquewhite;
display: block;
// padding: 6rem;
overflow-y: scroll;

& > ._Header {
width: 100%;
height: 100px;
padding: 4rem 6rem;
background: antiquewhite;
background: linear-gradient(
   45deg,
   #20B2AA 0%,
   #48D1CC 20%,
   #00CED1 40%,
   #5F9EA0 60%,
   #4682B4 80%,
   #008B8B 100%
);
}

& > ._Main {
width: 100%;
height: 100%;
background: #eee;
padding: 4rem 8rem;
position: relative;

  ._Navbar {
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  // justify-content: flex-end;
  background: #fff;

    ._Avatar {
    flex-shrink: 0;
    height: 100%;
    aspect-ratio: 1 / 1;
    // position: absolute;
    border-radius: 100%;
    border: 4px solid #fff;

      img { 
      width: 100%;
      height: 100%; 
      border-radius: 100%;
      }

    }

    ._Name {
    margin-left: 2rem;
    font-family: 'Ginora Sans Bold';
    font-size: 4rem;
    }
    
    ._Button {
    display: flex;
    margin-left: 2rem;
    width: auto;
    padding: 0.5rem 1rem;
    background-color: antiquewhite;
    background: rgba(0, 0, 0, 0.1);
    font-size: 2rem;
    color: #444;
    border: 1px solid #777;
    border-radius: 50px;
    cursor: pointer;
    
      &:hover {
      background: rgba(255, 255, 255, 0.3);
      }

      ._Icon { margin-right: 1rem; }

    }

  }

}

`;

return Style;

}

