
  import { Styled } from './Style/Style_App_Access.js';
  import { App_Login } from './Parts/Login/App_Login.js';
  import { App_Sign } from './Parts/Sign/App_Sign.js';
  import { App_Sign_Verify } from './Parts/Verify/App_Sign_Verify.js';

export const App_Access = ({ Props }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/

  let URI = `${Public.ENV.URL}/Apps/Access`;
  const { useRef, useState, useEffect, useLayoutEffect } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const history = RouterDOM.useHistory();
  const location = RouterDOM.useLocation();

  // Refs
  const theForm = useRef(null);

  const [ID, SetID] = useState(null);

  const backgroundRef = useRef(null);
  const loginTextRef = useRef(null);
  const signTextRef = useRef(null);

/*████████████████████████████████   🦉 SCRIPTS   ██████████████████████████████████*/

  const toggleMode = (mode) => {
    const ANIMATION_DURATION = 300;
    if (backgroundRef.current && loginTextRef.current && signTextRef.current) {
      if (mode === 'Sign') {
        backgroundRef.current.style.transform = 'translateX(100%)';
        signTextRef.current.style.color = '#fff';
        loginTextRef.current.style.color = '#777';
        setTimeout(() => history.replace('/sign'), ANIMATION_DURATION);
      } else {
        backgroundRef.current.style.transform = 'translateX(0)';
        loginTextRef.current.style.color = '#fff';
        signTextRef.current.style.color = '#777';
        setTimeout(() => history.replace('/login'), ANIMATION_DURATION);
      }
    }
  };

  useLayoutEffect(() => {

    if (backgroundRef.current) {
      // Set initial transform based on current location
      const initialTransform = location.pathname.startsWith('/sign') 
      || location.pathname.startsWith('/verify')
        ? 'translateX(100%)' 
        : 'translateX(0)';
      
      backgroundRef.current.style.transform = initialTransform;
      
      /* Si no estamos en ninguna de las rutas permitidas, redirigir a login
      if (!location.pathname.startsWith('/login') 
       && !location.pathname.startsWith('/sign')) {
        history.replace('/login');
      }
      */

    }
  }, [location.pathname]);

/*█████████████████████████████████   💻 RENDER   ███████████████████████████████████*/

return (html`
<${Style}>
<div className="_Container">

  <div className="_Sidebar">
    <div className="_Background">
      <img className="_Background" src="${URI}/Media/Access.png"/>
    </div>
  </div>

  <div className="_Main">

    <div className="_Toggle_Container">
      <div className="_Toggle_Background" ref=${backgroundRef}/>
      <div
        ref=${loginTextRef}
        className=${`_Toggle_Option ${location.pathname.startsWith('/login') || location.pathname.startsWith('/restore') ? '_Active' : ''}`}
        onClick=${() => toggleMode('Login')}>
        Entrar
      </div>
      <div
        ref=${signTextRef}
        className=${`_Toggle_Option ${location.pathname.startsWith('/sign') || location.pathname.startsWith('/verify') ? '_Active' : ''}`}
        onClick=${() => toggleMode('Sign')}>
        Unirse
      </div>
    </div>

    <${RouterDOM.Switch}>

      <${RouterDOM.Route} exact path=${["/login"]}>
        <${App_Login} Props=${Props}/>
      <//>

      <${RouterDOM.Route} exact path=${["/sign"]}>
        <${App_Sign} Props=${Props}/>
      <//>

      <${RouterDOM.Route} exact path=${["/verify", "/verify/:id"]}>
        <${App_Sign_Verify} Props=${Props}/>
      <//>

    <//>

  </div>

</div>
<//>
`);
  
};