export const Text = (styled, Proto, Props) => {
return styled.css`

  .Text_Icon {
  --ColorShadow1: rgba(128, 128, 128, 0.8);
  --ColorLight1: rgba(235, 235, 235, 0.2);
  --ColorLight2: rgba(205, 205, 205, 0.3);
  text-shadow: 
  1px 0 var(--ColorShadow1),  0 1px var(--ColorShadow1),  -1px 0 var(--ColorShadow1),  0 -1px var(--ColorShadow1),
  2px 0 var(--ColorLight1),  0 2px var(--ColorLight1),  -2px 0 var(--ColorLight1),  0 -2px var(--ColorLight1);
  // 4px 0 var(--ColorLight2),  0 4px var(--ColorLight2),  -4px 0 var(--ColorLight2),  0 -4px var(--ColorLight2)
  // ;
  }

  ._Key {
  font-size: 0.8em;
  display: inline-block;
  padding: 1rem 1.5rem;
  margin: 0.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  // font-weight: bold;
  color: #333;
  background-color: #f0f0f0;
  border-radius: 6px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(255, 255, 255, 0.3);
  text-align: center;
  user-select: none;
  transition: all 0.2s ease;
  }

  ._Key:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), inset 0 2px 3px rgba(0, 0, 0, 0.1);
  transform: translateY(1px);
  }

  .Title {
  width: 100%;
  text-align: center;
  font-family: 'Ginora Sans';
  color: #777;
  opacity: 0.7;
  font-size: 3rem;
  margin-top: 2rem;
  }

`}