//----------------------------------------------------------------------------------------
  export class Validation { constructor () {
//----------------------------------------------------------------------------------------

  this.errors = {
    email: "El formato del email es inválido.",
    minLength: length => `Debe tener al menos ${length} caracteres.`,
    required: "Este campo es obligatorio.",
    alphanumeric: "Solo se permiten caracteres alfanuméricos.",
    pattern: "El formato del texto es inválido."
  }

}
//----------------------------------------------------------------------------------------
  Form (e, Callback) {
//----------------------------------------------------------------------------------------

  e.preventDefault(); 
  const form = e.target;
  const fields = form.querySelectorAll("[data-validation]");
  let isFormValid = true;

  const validators = {
    email: input => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input),
    minLength: (input, length) => input.length >= length,
    required: input => input.trim().length > 0,
    alphanumeric: input => /^[a-zA-Z0-9]+$/.test(input),
    pattern: (input, pattern) => pattern.test(input)
  }

  fields.forEach(field => {
    const validationType = field.getAttribute("data-validation")
    const [type, param] = validationType.split(":")
    const isValid = validators[type](field.value, param)
    
    this.clearError(field)
    
    if (!isValid) {
      isFormValid = false
      const errorMessage = typeof this.errors[type] === "function" ? this.errors[type](param) : this.errors[type]
      this.showError(field, errorMessage)
    }
  })

  if(isFormValid) { Callback(); }
  else {

  }

  return isFormValid;

}
//----------------------------------------------------------------------------------------
  showError (field, message) {
//----------------------------------------------------------------------------------------
  const Input_Msg = document.createElement("span")
  Input_Msg.className = "Input_Msg"
  Input_Msg.innerText = message
  field.insertAdjacentElement("afterend", Input_Msg)
}
//----------------------------------------------------------------------------------------
  clearError (field) {
//----------------------------------------------------------------------------------------
  const Input_Msg = field.nextElementSibling
  if (Input_Msg && Input_Msg.classList.contains("Input_Msg")) {
    Input_Msg.remove()
  }
}
//----------------------------------------------------------------------------------------
}