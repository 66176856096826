export const Style = (styled, Proto, Props) => {

return styled.div`
width: 100%;
height: 100%;
max-height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

// Transparency effect
.Section_Community {
  .Boxes {
    .Box {
      // opacity: 0.85;
      // transition: opacity 2s ease;
    }
    &:hover {
      // .Box { opacity: 0.85;}
      // .Box:hover {opacity: 1;}
    }
  }
}

.Section_Community {
width: 100%;
height: 80%;
overflow-y: scroll;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 2rem 4rem;

  .Boxes {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly; 

  @media only screen and (max-width: 50rem) {
    display: block;
  }

  .Box {
  width: 30%;
  border-radius: 9px;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  overflow: hidden;
  background-color: #eee;
  color: #eee;
  border: transparent 1px solid;

    @media only screen and (max-width: 50rem) {
      width: 100%;
      height: 50%;
      margin-bottom: 5%;
    }
      
    &.YouTube { border-color: #cf3932; background: rgba(207, 57, 50, 0.2); }
    &.Instagram { border-color: #e79ee8; background: rgba(231, 158, 232, 0.2); }
    &.X { border-color: #222; background: rgba(34, 34, 34, 0.2); }

    a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    width: 100%;
    height: 100%;
    // background-color: aliceblue;
    }

    h2 {
    width: 100%;
    position: absolute;
    top: 4rem;
    margin-top: 2.2rem;
    font-size: 7rem;
    // color: #fff;
    color: #eee;
    text-align: center;
    z-index: 2;
    padding: 0 2rem;
    font-family: "Ginora Sans";
    font-weight: bold;
    }

    &:last-child .Separator{
    visibility: hidden;
    }

  // Box:hover
  &:hover .movil {
  // top: 10%;
  // animation: Mobile_Animation 0.5s ease forwards;
  animation: Mobile_Animation 0.5s ease forwards;
  }

  &.YouTube .movil{ background: #cf3932;  }
  &.Instagram .movil{ background: #F4A9C8;  }
  &.X .movil{ background: #444; }

  &.YouTube h2 { color: #cf3932; }
  &.Instagram h2 { color: #F4A9C8; }
  &.X h2 { color: #222; }


  } // Box

} // Boxes


@keyframes Mobile_Animation {
  from {
  top: 80%; 
  // img { filter: grayscale(100%);}
  }
  to {
  top: 10%;
  // opacity: 1;
  // img { filter: grayscale(0%);}
  }
}

  .movil {
  position: absolute;
  top: 88%;
  left: 10%;
  width: 80%;
  height: 600px;
  background-color: #2c1c1e;
  border: 1px solid rgba(0, 0, 0, 0.28);
  border-radius: 30px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  overflow: hidden;
  z-index: 2;
  transition: top 2s ease-in-out;
  
    &::before {
    content: '';
    position: absolute;
    width: 140px;
    height: 12px; top: 24px;
    background-color: #2c1c1e;
    border-radius: 0 0 10px 10px;
    z-index: 3;
    }

  }

  .pantalla {
  width: calc(100% - 16px); /* Ajusta el ancho para simular bordes del móvil */
  height: calc(100% - 18px); /* Ajusta el alto considerando la muesca y el borde inferior */
  border-radius: 20px;
  background: #333;
  padding: 0.7rem;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
  position: relative;
  z-index: 2;
  // display: flex;

    iframe, img {
    width: 100%;
    border-radius: 20px;
    opacity: 1;
    // opacity: 0.5;
    // filter: grayscale(100%);
    }

  }

  .botones {
  position: absolute;
  width: 3px;
  height: 50px;
  background: rgba(255,255,255,0.5);
  left: -10px;
  top: 100px;
  border-radius: 1px;
  }

  .sensor {
  position: absolute;
  width: 60px;
  height: 10px;
  background: rgba(0,0,0,0.5);
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  }

} // Section_Community

`;

}