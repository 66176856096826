export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: auto;
min-height: 100%;
overflow-y: scroll;
color: #333;
// background-color: antiquewhite;
display: flex;
flex-direction: column;

  & > ._Main {
  width: 100%;
  height: auto;

  display: block;
  padding: 4rem 6rem;
  // margin-bottom: 10rem;
  }

  a._Banner {
  width: 100%;
  height: 100%;
  // aspect-ratio: 4.2;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end; 
  overflow: hidden;
  padding: 2rem;

    &:last-child {
    margin-bottom: 0;
    }

    .BannerImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
    opacity: 0.7;
    }

    .ShadowLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(7px);
    transition: all 0.5s;
    // background-color: rgba(0, 0, 0, 0.5);
    // transition: opacity 0.5s;
    }

    ._Title {
    width: auto;
    height: auto;
    z-index: 12;
    color: #eee;
    font-family: 'Ginora Sans';
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: end;
    
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    transition: opacity 0.5s;
    }

    &:hover {
    cursor: pointer;
    border-color: #4edbf2;
    border-color: aqua;

      .BannerImage {
        opacity: 1;
      }
      
      .ShadowLayer {
      backdrop-filter: blur(0px);
      background-color: rgba(255, 255, 255, 0.1);
      opacity: 1;
      }

      ._Title {
        background-color: rgba(0, 0, 0, 0.7);
      }

    }

  }

  a._Banner2 {
  width: 100%;
  position: relative;
  display: block;
  padding: 0rem;
  }

  ._Follow {
  width: 100%;
  height: auto;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.7s ease-in;


    @keyframes girar {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    ._Image {
    object-fit: cover;
    display: block;
    margin-right: 4rem;
    opacity: 0.7;

    display: inline-block;
    transform-origin: center;
    // animation: girar 10s linear infinite;
    transition: all 0.7s ease-in;
    }

    ._Title {
    font-size: 2rem;
    font-size: 'Ginora Sans Bold';
    opacity: 0.4;
    margin: 0;
    transition: all 0.7s ease-in;
    }

    &:hover {
    background-color: rgba(255, 160, 64, 0.2);
    cursor: pointer;

      ._Image {
      opacity: 1;
      }

      ._Title {
      opacity: 0.7;
      }

    }

  }

`;

return Style;

}