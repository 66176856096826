import { Styled } from './Style/Style_App_Sign.js';

export const App_Sign = ({ Props }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/

  let URI = `${Public.ENV.URL}/Apps/Sign`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const history = RouterDOM.useHistory();

  // Refs
  const theForm = useRef(null);

  const [ID, SetID] = useState(null);

/*████████████████████████████████   🦉 SCRIPTS   ██████████████████████████████████*/

  const Sign = async (e) => {

    e.preventDefault();
    const sendForm = new FormData(theForm.current);
    await fetch(`${Public.ENV.URL}/user/access/sign`, { 
      method: 'POST',
      body: sendForm,
    })
    .then(fetch => fetch.json())
    .then(Result => {
      
      // Result
      console.log(Result);
      if(Result.Status !== "OK") { return; }
      
      // Notification
      Public.Scripts.Notify.Send({
        Title: "Correo de confirmación", 
        Description: `¡Un correo ha sido enviado para activar tu cuenta!`, 
        Image: "Email",
        Time: 5000
      });

      // URL Confirm
      history.push(`/verify`, { Email: Result.Email });

    });

  }
   
/*█████████████████████████████████   💻 RENDER   ███████████████████████████████████*/

return (html`
<${Style}>
<form className="Form" ref=${theForm} 
onSubmit=${(e)=> { Public.Scripts.Validation.Form(e, () => Sign(e)); }}>

  <div className="Container_Input">
    <div className="Input">
      <i className="icofont-user icofont-1x" />
      <input type="text" name="ID" placeholder="Nombre" tabIndex="1" autoFocus autoComplete="off" data-validation="required" />
    </div>
  </div>
  <div className="Container_Input">
    <div className="Input">
      <i className="icofont-email icofont-1x" />
      <input type="text" name="Email" placeholder="Email" tabIndex="2" autoComplete="off" data-validation="email" />
    </div>
  </div>
  <div className="Container_Input">
    <div className="Input">
      <i className="icofont-ui-password icofont-1x" />
      <input type="password" name="Password" placeholder="Contraseña" tabIndex="3" autoComplete="new-password" data-validation="minLength:6" />
    </div>
  </div>
  <div className="checkbox-wrapper-60">
    <input type="checkbox" className="check" id="check1-60" tabIndex="4" />
    <label htmlFor="check1-60" className="label">
    <svg viewBox="0 0 65 65" height="30" width="30">
      <rect x="7" y="7" width="50" height="50" stroke="black" fill="none" />
      <g transform="translate(-23,-967.36216)" id="layer1-60">
        <path id="path4146" d="m 55,978 c -73,19 46,71 15,2 C 60,959 13,966 30,1007 c 12,30 61,13 46,-23" fill="none" stroke="black" strokeWidth="3" className="path1" />
      </g>
    </svg>
    <span>Acepto los <${RouterDOM.Link} target="_blank" to="/legal">Términos y Condiciones<//>.
    </span>
  </label>
  </div>
  <div className="Container_Input">
    <input className="Input_Button" type="submit" value="Registro" tabIndex="5" />
  </div>

</form>
<//>
`);
  
};