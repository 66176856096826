export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
display: flex;
// padding: 6rem;
// background: antiquewhite;

// background: linear-gradient(135deg, #A0C4FF, #FFABAB);
// background: #555;

._Container {
width: 100%;
// height: 100%;
overflow: hidden;
border-radius: 7px;
display: flex;
flex-direction: row;
// box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  ._Background {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;

    img {
    width: 100%;
    object-fit: cover;
    }
  }

}

._Sidebar {
width: 50%;
height: 100%;
overflow: hidden;
position: relative;

display: flex; /* Usa flexbox para centrar */
justify-content: center; /* Centra horizontalmente */
align-items: center;

  ._Background {
  z-index: 0;
  }

}

._Main {
width: 50%;
// height: 100%;
display: block;
padding: 4rem 10%;

background: rgba(20, 20, 20, 0.5);
background: rgba(240, 240, 240, 0.7);
backdrop-filter: blur(10px);

  ._Title {
  width: 100%;
  text-align: center;
  font-family: 'Ginora Sans';
  color: rgba(255, 255, 255, 0.7);
  color: rgba(20, 20, 20, 0.7);
  color: rgba(104, 103, 166, 0.7);
  font-size: 3rem;
  margin: 6rem 0;
  }

  ._Options {
  width: 100%;
  display: block;
  margin-top: 4rem;

    ._Or_Sign {
    margin-top: 2rem;
    display: block;
    text-align: center;
    color: rgba(20, 20, 20, 0.5);
    color: #777;
    
      &:hover {
      color: #555;
      }

    }

  }

}


`;

return Style;

}