export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: 100%;
color: #333;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

.App_About_Detail {
width: 100%;
height: 100%;
padding: 0;
display: flex;
flex-direction: column;

  ._Main {
  width: 100%;
  height: auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: aqua;

    & > ._Container {
    height: 100%;
    max-width: 740px;
    }

  }

  #_Page {
  font-size: 1.9rem;
  padding-bottom: 4rem;
  color: #777;
  display: block;

    ._initori {
    width: auto;
    height: 1.8rem;
    color: #444;
    font-family: "Ginora Sans Bold";
    // font-weight: bold;
    position: relative;
    padding-left: 2rem;
     
      img { 
      position: absolute;
      top: calc(50% - 0.9rem);
      left: 0;
      width: 1.8rem; 
      margin: 0 0.1rem; 
      vertical-align: middle;
      }

    }

    strong {
    color: #555;
    }

    p { 
    text-align: justify; 
    }

    h2 {
    font-family: "Ginora Sans Bold";
    font-weight: bold;
    color: #758cd1;
    padding-bottom: 2rem;
    margin-bottom: 0rem;
    // border-bottom: 1px dashed #758cd1;
    }

    h3 {
    font-family: "Ginora Sans Bold";
    font-size: 2.2rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #777;
    }

    img { 
    max-width: 100%; 
    justify-content: center;
    margin: 2rem 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
    }

    code { 
    background-color: #777;
    color: #eee;
    border-radius: 20px;
    font-size: 0.9em;
    padding: 0.5rem 1rem;
    }

    ._Section {
    background-color: rgba(250, 235, 215, 0.7);
    display: flex;
    padding: 3rem 2rem;
    margin: 3rem 0;
    border-radius: 7px;

      @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      }
    

      ._Img { 
      width: 50%;
      height: auto;
      flex: 1;
      margin: 0 2rem;

        img {
        width: 100%;
        object-fit: contain;
        overflow: hidden;
        border-radius: 10px;
        }

        @media (max-width: 768px) {
        width: 100% !important;
        }

      }

      ._Text {
      width: 100%;
      font-size: 0.9em;
      margin: 2rem 2rem;
      flex: 1;
      }

    }
  

  }

  ._Text {
    
  }

  ._Image {
  display: flex;
  justify-content: center;
  margin: 2rem 0;

    img {
    max-width: 420px;
    }

  }


}

`;

return Style;
}
