import { Styled } from './Style/Style_App_User_Detail.js';

export const App_User_Detail = ({ Props }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/

  let URI = `${Public.ENV.URL}/Apps/Main/Landing`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const RouterDOM_History = RouterDOM.useHistory();
  const {id} = RouterDOM.useParams();

/*████████████████████████████████   🦉 SCRIPTS   ██████████████████████████████████*/

  const [Item, SetItem] = useState(null);
    
  useEffect(() => { Get_Item(); }, []);

  const Get_Item = async () => {
    Public.DB.Read({
      URI: `Users`,
      Items: [Public.TOKEN.User_ID + ""], 
      Callback: (items) => { SetItem(items[0]); }
    });
  }

  useEffect(() => { if(!Item) { return }
    console.info("Item:", Item);
  }, [Item]);

  const Logout = async (e) => {

    e.preventDefault();
    await fetch(`${Public.ENV.URL}/user/access/logout`, { 
      method: 'POST',
      body: undefined,
    })
    .then(fetch => fetch.json())
    .then(res => { 
      
      console.log(res); 
      RouterDOM_History.push('/login');
      // location.reload();

      Public.Scripts.Notify.Send({
        Title: "Sesión cerrada", 
        Description: `¡Hasta pronto!`, 
        Image: "User", 
        Time: 3000
      });

    });

  }

/*█████████████████████████████████   💻 RENDER   ███████████████████████████████████*/

return (html`
<${Style}>
  
  <!--<div className="_Header">
    
  </div>-->

  <div className="_Main">
    
    <div className="_Navbar">

      <div className="_Avatar" >
        <img src=${Public.Scripts.Blob_To_Img_URL(Item?.Files[0].Blob)}/>
      </div>
      
      <div className="_Name">${Item?.Name}</div>

      ${Public.TOKEN.Power !== 'USER'  && html`
      <!--<div className="_Box">
        <span>🪙</span>
        <strong></strong>
        ${Public.TOKEN.Power}
      </div>-->
      `}

      <div className="_Button" onClick=${(e) => { Logout(e); }}>
        <div className="_Icon">👤</div>
        <strong>Logout</strong>
      </div>

    </div>

  </div>  

<//>
`);
  
};