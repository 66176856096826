export const Style = (styled, Proto, Props) => { const Style = styled.div`

#App_Menu {
display: flex;
flex-direction: column;

  @media only screen and (max-width: 40rem) {
  width: 80%;
  flex-direction: row;
  flex-wrap: nowrap;
  
    a {
      flex: 0 0 25%;
    }

  }

}

li {
width: 100%;
height: 100%;
font-size: 16px;
color: #eee;
display: flex; 
align-items: center;
justify-content: center;
position: relative;
overflow: hidden;

  ._Icon{
  position: relative;
  z-index: 1;
  transition: 0.11s;
  width: 48px;
  // height: 48px;
  font-size: 1.6em;
  filter: grayscale(70%); //brightness(0.5) sepia(1) saturate(100%);
  opacity: 0.7;
  transition: all 0.3s ease;
  }

  ._Halo {
  background-image: inherit;
  transition: all 0.7s ease;
  }

  &:hover{
  cursor: pointer;
  
    ._Icon{ 
    filter: grayscale(0%);
    }
  }

  &.Active{     

    ._Icon{
    opacity: 1;
    filter: grayscale(0%);
    // filter: brightness(0.5) sepia(1) saturate(250%) //hue-rotate(120deg);
    }

    ._Halo {
    opacity: 0 !important;
    }

  }

}

`; return Style; }