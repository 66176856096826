export const Input = (styled, Proto, Props) => { return styled.css`

.Form {

  /*
  input[type="text"], input[type="password"] { //, input[type="submit"] {
  width: 100%;
  font-size: 2rem;
  padding: 2rem;
  letter-spacing: 0.025rem;
  color: #777;
  background: #fff;
  background: rgba(255, 255, 255, 0.2);
  // background: transparent;
  border-radius: 50px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border: 2px solid #ccc;
  
    &:hover {
    // border: 2px solid aqua;
    }

  }
  */

  button {
  width: 100%;
  padding: 2rem;
  border: none;
  border-radius: 4px;
  background-color: #0056b3;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 1rem 0;
  text-align: center;
  color: #fff;
  }

  button:hover {
  background-color: #003d82;
  }

  .Input_Msg {
  display: block;
  color: #555;
  padding-left: 2rem;
  margin-top: 1rem;
  }

}

.Container_Input_Range {
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 1rem;
}

  .Input_Range {
  width: 100%;
  height: 100%;
  }

  .Input_Range_Text {
  width: 100%;
  height: 100%;
  margin-left: 1rem;
  }

.Container_Input {
width: 100%;
height: auto;
margin-top: 2rem;
display: flex;
align-items: center;
justify-content: center;
margin-top: 3rem;
}

.Input_Label {
width: 50%;
font-size: 1.8rem;
padding-left: 1rem;
color: #555;
// text-transform: uppercase;
font-family: 'Ginora Sans';
font-weight: bold;
--mi-color-sombra: rgba(225, 225, 225, 0.3);
  text-shadow: 
    1px 0 var(--mi-color-sombra), 
    0 1px var(--mi-color-sombra), 
    -1px 0 var(--mi-color-sombra), 
    0 -1px var(--mi-color-sombra);
}

.Input_Text {
width: 100%;
width: 100%;
// margin-bottom: 1rem;
padding: 1rem;
border: 1px solid #cccccc;
border-radius: 4px;
box-sizing: border-box;
}

.Input_Color {
width: 100%;
}

.Textarea {
width: 100%;
padding: 2rem;
border: 2px solid #ccc;
border-radius: 10px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
resize: none;
transition: box-shadow 0.3s, border-color 0.3s;
line-height: 1.5;
vertical-align: top; 
font-family: 'Ginora Sans';


  &:focus {
  outline: none;
  border-color: aqua;
  box-shadow: 0 0 8px rgba(0, 0, 255, 0.5);
  }

}

@property --gradient-color-1 {
  syntax: '<color>';
  initial-value: #A7C7FF;
  inherits: false;
}

@property --gradient-color-2 {
  syntax: '<color>';
  initial-value: #007BFF;
  inherits: false;
}

.Input_Button {
font-family: 'Ginora Sans Bold';
display: inline-block;
width: 100%;
padding: 2rem;
font-size: 2rem;
margin-top: 1rem;
color: white;
border: none;
cursor: pointer;
text-align: center;
text-decoration: none;
border-radius: 50px;

background: linear-gradient(45deg, 
            var(--gradient-color-1), 
            var(--gradient-color-2));
transition: transform 1s ease,
          --gradient-color-1 1s ease,
          --gradient-color-2 1s ease;

  &:hover {
    --gradient-color-1: #007BFF;
    --gradient-color-2: #A7C7FF;
  }

  &:active {
  }

}

.Input {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  i {
    position: absolute;
    left: 3rem;
    color: #777;
    z-index: 1;
  }

  & > input {
    width: 100%;
    font-size: 2rem;
    padding: 2rem;
    padding-left: 6rem !important;
    letter-spacing: 0.025rem;
    color: #777;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: 2px solid #ccc;
    border: 2px solid #efefef;

    &:hover {
      border: 2px solid #efefef;
      box-shadow: 1px 1px 1px rgba(0, 255, 255, 0.7), -1px -1px 1px rgba(0, 255, 255, 0.7);
    }
    
    &:focus {
      border: 2px solid aqua;
    }

  }
}

// https://getcssscan.com/css-checkboxes-examples

.checkbox-wrapper-60 input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  pointer-events: all;
  margin: -1px;
  padding: 0;
}

.checkbox-wrapper-60 *,
.checkbox-wrapper-60 ::after,
.checkbox-wrapper-60 ::before {
  box-sizing: border-box;
}

.checkbox-wrapper-60 {
  width: 100%;
  margin-top: 2rem;
  padding-left: 2rem;
  position: relative;
  display: block;
  overflow: hidden;
  font-family: 'Ginora Sans';
  color: #777;
}

.checkbox-wrapper-60 span {
  margin-left: 1rem;
}

.checkbox-wrapper-60 a {
  font-family: 'Ginora Sans Bold';
  color: #0056b3;
}

.checkbox-wrapper-60 a:hover {
  color: #003d82;
}

.checkbox-wrapper-60 .check {
  width: 50px;
  height: 50px;
  position: absolute;
  opacity: 0;
}

.checkbox-wrapper-60 .label svg {
  vertical-align: middle;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.checkbox-wrapper-60 .path1 {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  transition: .5s all;
}

.checkbox-wrapper-60 .check:checked + label svg g path {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-60 .check:focus + label svg,
.checkbox-wrapper-60 .check:active + label svg,
.checkbox-wrapper-60 .label:hover svg {
  border-color: #0056b3;
}

`}