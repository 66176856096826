//----------------------------------------------------------------------------------------

  import { Styles } from './Styles/Styles_Board_Items.js';
  import { Board_Items_Navbar } from "./Navbar/Board_Items_Navbar.js";

  import { Items_Dashboard } from "./Dashboard/Items_Dashboard.js";
  import { Items_Chips } from "./Chips/Items_Chips.js";
  import { Items_Books } from "./Books/Items_Books.js";
  import { Items_Cards } from "./Cards/Items_Cards.js";
  import { Items_Merch } from "./Merch/Items_Merch.js";
  import { Items_Asset } from "./Asset/Items_Asset.js";

//----------------------------------------------------------------------------------------
  export const App_Items = ({ Props }) => {
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, memo } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Styles, {});
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  let URI_Media = `${Public.ENV.URL}/Apps/Items/Media`;

  const thePanel = useRef(null);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
return (html`
<${Styled} ref=${thePanel}>

  <${RouterDOM.Route} exact path='/Items/:type'>
    <${Board_Items_Navbar} Props=${Props} />
  <//>

<div className="Board_Items_Main">

  <${RouterDOM.Route} exact path='/Items'>
    <${Items_Dashboard} Props=${Props} />
  <//>

  <${RouterDOM.Route} path='/items/*'>
    <${Items_Chips} Props=${Props} />
  <//>

  <${RouterDOM.Route} path='/Items/Cards'>
    <${Items_Cards} Props=${Props} />
  <//>

  <${RouterDOM.Route} path='/Items/Books'>
    <${Items_Books} Props=${Props} />
  <//>

  <${RouterDOM.Route} path='/Items/Merch'>
    <${Items_Merch} Props=${Props} />
  <//>

  <${RouterDOM.Route} path='/Items/Assets'>
    <${Items_Asset} Props=${Props} />
  <//>

</div>

<//>
`);
}