//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_App_Start.js';

//----------------------------------------------------------------------------------------

export const App_Start = ({ Props }) => {

  let URI = `${Public.ENV.URL}/Apps/Start`;
  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  // const Part_Parallax = Public.Scripts.Choose_Part('Parallax');  

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const [Loaded, SetLoaded] = useState(false);
  const [Images, SetImages] = useState([
    {URL: undefined},
    {URL: `${URI}/Media/Parallax/1.png`},
    {URL: `${URI}/Media/Parallax/2.png`},
  ]);

  const Component_Box = Public.Scripts.Choose_Part('Box');  

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  useEffect(() => {
    SetLoaded(true);
  }, []);
  
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled}>

<div className="Navbar">

  <!--<h2 className="_Title">Inicio</h2>-->

  <div className="_Menu">
    <${RouterDOM.Link} to="/about" className="_Item ${location.pathname.startsWith('/about') ? "_Active" : ""}">
      <div className="_Icon">⛩️</div> initori
    <//>
    <${RouterDOM.Link} to="/shop" className="_Item ${location.pathname.startsWith('/shop') ? "_Active" : ""}">
      <div className="_Icon">🪙</div> Tienda
    <//>
  </div>

  <div className="_Menu">
    <${RouterDOM.Link} to="/login" className="_Item ${location.pathname.startsWith('/login') ? "_Active" : ""}">
      <div className="_Icon">👤</div> Acceder
    <//>
    <${RouterDOM.Link} to="/sign" className="_Item ${location.pathname.startsWith('/sign') ? "_Active" : ""}">
      <div className="_Icon">🌐</div> Unirse
    <//>
  </div>

</div>

<div className="_Main">

  <div className="_Container _Vertical _Margin_Bottom_4">

    <${RouterDOM.Link} to="/items/chips/1" key="Start_Blog" className="_Banner2">
      <${Suspense} fallback=${html`<div></div>`}>
        ${html`<${Component_Box}
        URI=${`${URI}/Media/Banner/Aura_Adventure.png`}
        Hemisphere=${40}
        Bottom=${-15}
        Movement=${20}
        Title="🎮 Aura Adventure"
        Background="linear-gradient(to top, #ffe4e1 0%, #ffecd2 35%, #ffd1e8 100%)"
        Overflow=${{ x: false, y: true }}
        />`}
      <//>
    <//>

    <${RouterDOM.Link} to="/about" key="Start_About" className="_Banner2">
      <${Suspense} fallback=${html`<div></div>`}>
        ${html`<${Component_Box} 
        URI=${`${URI}/Media/Banner/About.png`} 
        Hemisphere=${40}
        Bottom=${-25}
        Movement=${10}
        Title="⛩️ initori"
        Background="linear-gradient(to top, #ffecd2 0%, #c4e0f3 35%, #a6d4f3 100%)"
        Overflow=${{ x: false, y: true }}
        />`}
      <//>
    <//>
    

  </div>
  
  <div className="_Container _Vertical _50 _Margin_Bottom_4">

    <${RouterDOM.Link} to="/community" key="Start_Community" className="_Banner2">
    <${Suspense} fallback=${html`<div></div>`}>
      ${html`<${Component_Box} 
      URI=${`${URI}/Media/Banner/Digitori.png`} 
      Hemisphere=${50}
      Bottom=${-30}
      Movement=${12}
      Title="👥 Comunidad"
      Background="radial-gradient(circle at center, #ffffff 0%, #d0e7ff 100%)"
      />`}
    <//>
    <//>

    <${RouterDOM.Link} to="/shop" key="Start_Shop" className="_Banner2">
      <${Suspense} fallback=${html`<div></div>`}>
        ${html`<${Component_Box} 
        URI=${`${URI}/Media/Banner/Shop.png`} 
        Hemisphere=${10}
        Bottom=${0}
        Movement=${10}
        Title="👛 Tienda"
        Background="radial-gradient(circle at center, #ffffff 0%, #ffefba 100%)"
        />`}
      <//>
    <//>

    <${RouterDOM.Link} to="/roadmap" key="Start_Blog" className="_Banner2">
    <${Suspense} fallback=${html`<div></div>`}>
      ${html`<${Component_Box} 
      URI=${`${URI}/Media/Banner/Roadmap.png`} 
      Hemisphere=${40}
      Bottom=${-24}
      Movement=${10}
      Title="🗺️ Roadmap"
      Background="radial-gradient(circle at center, #ffffff 0%, #e0f4e7 50%, #e8f5e9 100%)"
      Overflow=${{ x: false, y: true }}
      />`}
    <//>
    <//>

  </div>

  <${RouterDOM.Link} to="/community" key="Start_Blog" className="_Banner2">
  <div className="_Follow">

    <div className="_Image">
      <img src="${URI}/Media/Banner/Social.png" />
    </div>

    <div className="_Title">
      <h3>¡Sigue a Aura!</h3>
      <p></p>
    </div>

  </div>
  <//>

</div>

<//>
`);

};