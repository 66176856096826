export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
display: flex;
// padding: 6rem;
// background: antiquewhite;

// background: linear-gradient(135deg, #A0C4FF, #FFABAB);
// background: #555;

._Container {
width: 100%;
// height: 100%;
overflow: hidden;
border-radius: 7px;
display: flex;
flex-direction: row;
// box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  ._Background {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;

    img {
    width: 100%;
    object-fit: cover;
    }
  }

}

._Sidebar {
width: 50%;
height: 100%;
overflow: hidden;
position: relative;

display: flex; 
justify-content: center;
align-items: center;

  ._Background {
  z-index: 0;
  }

}

._Main {
width: 50%;
// height: 100%;
display: block;
padding: 4rem 10%;

background: rgba(20, 20, 20, 0.5);
background: rgba(240, 240, 240, 0.7);
backdrop-filter: blur(10px);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

  ._Toggle_Container {
  width: fit-content;
  min-height: 48px;
  border-radius: 50px;
  padding: 4px;
  position: relative;
  margin-bottom: 2rem;
  overflow: hidden;
  cursor: pointer;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: -1px -1px 1px rgba(25, 25, 25, 0.05), -1px -1px 1px rgba(25, 25, 25, 0.05),
  2px 2px 2px rgba(255, 255, 255, 0.3), 2px 2px 2px rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  }

  ._Toggle_Option {
  font-family: 'Ginora Sans Bold';
  padding: 2rem 4rem;
  border-radius: 50px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  z-index: 2;
  color: #777;
  position: relative;
  transition: color 0.3s ease;
  cursor: pointer;
  user-select: none;
  }

  ._Toggle_Background {
  position: absolute;
  width: calc(50% - 4px); 
  height: calc(100% - 8px);
  background: rgb(0, 123, 255);
  border-radius: 50px;
  top: 4px;
  left: 4px;
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  transform: translateX(${location.pathname === '/sign' ? '100%' : '0'});
  }

  ._Toggle_Option._Active {
  color: #fff;
  }

  ._Title {
  width: 100%;
  text-align: center;
  font-family: 'Ginora Sans';
  color: rgba(104, 103, 166, 0.7);
  font-size: 3rem;
  margin: 6rem 0;
  }

  ._Options {
  width: 100%;
  display: block;
  margin-top: 4rem;

    ._Or_Sign {
    margin-top: 2rem;
    display: block;
    text-align: center;
    color: rgba(20, 20, 20, 0.5);
    color: #777;
    
      &:hover {
      color: #555;
      }

    }

  }

}


`;

return Style;

}