//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_Main.js';

  import { App_Aura } from '../Aura/App_Aura.js';
  import { App_MAIA } from '../Maia/App_MAIA.js';
  import { App_Start } from '../Start/App_Start.js';
  import { App_Blog } from '../Blog/App_Blog.js';
  import { App_Shop } from '../Shop/App_Shop.js';
  import { App_Roadmap } from '../Roadmap/App_Roadmap.js';
  import { App_Items } from '../Items/App_Items.js';
  import { App_User } from '../User/App_User.js';
  import { App_Digitori } from '../Digitori/App_Digitori.js';
  import { App_Community } from '../Community/App_Community.js';
  import { App_Access } from '../Access/App_Access.js';
  // import { App_About } from '../About/App_About.js';
  
//----------------------------------------------------------------------------------------

export const App_Main = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//████████████████████████████████████████████████████████████████████████████████████

  // Add DOM Node to "Props"
  const theMain = useRef(null);
  useEffect(() => { Props.Node.Main = theMain.current; }, [Props.Node]);

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled} ref=${theMain} id="Board_Main">

  <${RouterDOM.Switch}>

    <${RouterDOM.Route} exact path=${["/", "/Aura", "/aura"]}>
      <${App_Aura} Props=${Props}/>
    <//>

    <${RouterDOM.Route} path=${["/Start", "/start", "/Start/:section"]}>
      <${App_Start} Props=${Props}/>
    <//>

    <!--<${RouterDOM.Route} exact path=${["/About", "/about", "/about/:id"]}>
      
    <//>-->

    <${RouterDOM.Route} path=${["/Blog", "/blog"]}>
      <${App_Blog} Props=${Props}/>
    <//>

    <${RouterDOM.Route} path=${["/Roadmap", "/roadmap"]}>
      <${App_Roadmap} Props=${Props}/>
    <//>
    
    <${RouterDOM.Route} path=${["/shop"]}>
      <${App_Shop} Props=${Props}/>
    <//>

    <${RouterDOM.Route} path=${["/digitori", "/digitori:id", "/digitama", "/digitama/digitori"]}>
      <${App_Digitori}/>
    <//>

    <${RouterDOM.Route} path=${["/items", "/items/*"]}>
      <${App_Items} Props=${Props}/>
    <//>

    <${RouterDOM.Route} path="/user">
      <${App_User} Props=${Props}/>
    <//>

    <${RouterDOM.Route} exact path=${["/login", "/restore", "/sign", "/verify", "/verify/:id"]}>
      <${App_Access} Props=${Props}/>
    <//>

    <${RouterDOM.Route} exact path=${["/Community"]}>
      <${App_Community} Props=${Props}/>
    <//>

    <${RouterDOM.Route} exact path=${["/Maia", "/maia", "/maia/:ent", "/maia/:ent/:evt"]}>
      <${App_MAIA} Props=${{
        ENV: {
          User:  { ID: Public.Token.Get('ACCESS').User_ID },
          Chip:  { ID: 1 },
          Scene: { ID: null },
          Mode: Public.ENV.MODE,
        },
        Tree: {
          Client: {
            Style: {
              Case: false,
              Round: false,
            }
          },
          Render: {
            Style: {
              Transparent: false,
            }
          }
        }
      }} />
    <//>

  <//>

<//>
`);

};