import { Styled } from './Style/Style_App_Sign_Verify.js';

export const App_Sign_Verify = ({ Props }) => {

/*█████████████████████████████████ 📚 DEPS ████████████████████████████████████*/

  let URI = `${Public.ENV.URL}/Apps/Sign`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();
  const location = RouterDOM.useLocation();
  
  // Refs
  const theForm = useRef(null);
  const [Email, SetEmail] = useState(location.state?.Email);
  const [VerificationToken, SetVerificationToken] = useState(id);

/*████████████████████████████████ 🦉 SCRIPTS ██████████████████████████████████*/

  const Verify = async (verificationToken) => {

    let formData = new FormData();
    formData.append('Token', verificationToken);

    await fetch(`${Public.ENV.URL}/user/access/verify`, { 
      method: 'POST',
      body: formData,
    })
    .then(fetch => fetch.json())
    .then(Result => {
      
      // Result
      console.log(Result);
      if(Result.Status !== "OK") { return; }
      
      // Notification
      Public.Scripts.Notify.Send({
        Title: "Cuenta verificada", 
        Description: `¡Tu cuenta ya está activa!`, 
        Image: "User",
        Time: 5000
      });

      // Login automatically if verification is successful
      history.push(`/user`, { Welcome: true });

    });

  }

  useEffect(() => { if(!VerificationToken) { return; }
    Verify(VerificationToken);
  }, [VerificationToken]);

/*█████████████████████████████████ 💻 RENDER ███████████████████████████████████*/

return (html`
<${Style}>
  
  ${Email ? html`
  <p>📬 Hemos enviado un correo a <b>${Email}</b> para activar tu cuenta.</p>
  ` 
  : html`
  <p>📬 Hemos enviado un correo a tu dirección de email para activar tu cuenta.</p>
  ` 
  }
  
<//>
`);

};