//----------------------------------------------------------------------------------------
import { Style } from './Style/Style_App_About.js';
import { App_About_Detail } from './Detail/App_About_Detail.js';
//----------------------------------------------------------------------------------------
export const App_About = ({ Props }) => {
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const {chip_id, about_id} = RouterDOM.useParams();

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------
  const [Items, SetItems] = useState([]);
  const [Chip_ID, SetChip_ID] = useState(chip_id);
  const [selectedAboutId, setSelectedAboutId] = useState(about_id);

  useEffect(() => {
    Get_List();
  }, []);

  const Get_List = async () => {
    Public.DB.Read({
      URI: `Admin/About`,
      Items: ["ALL"],
      Interface: { ID: true, Title: true, Files: { ID: "Cover" } },
      Callback: (items) => {
        console.log("Admin/About", items[0].Files);
        SetItems(items);
      }
    });
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------
  return (html`
    <${Styled}>
      <!--<div className="Navbar">
        <h2 className="_Title">About</h2>
        <div className="_Space"></div>
      </div>-->
      <ul className="_About_List">
        ${Items && Array.isArray(Items) && Items.map((Item, i) => {
          const Icon_Blob = Item?.Files.find(file => file.ID === "Cover")?.Blob;
          console.log(Item?.Files)
          return html`
            <${RouterDOM.Link}
              to="/items/chips/${Chip_ID}/about/${Item.ID}"
              key="Admin_About_List_${Item.ID}"
              onClick=${(e) => {
                e.preventDefault();
                history.pushState(null, '', `/items/chips/${Chip_ID}/about/${Item.ID}`);
                setSelectedAboutId(Item.ID);
              }}>
              <li className="${selectedAboutId === Item.ID && '_Active'}">
                <div className="_Thumbnail">
                  ${Icon_Blob && html`<img className="_Icon" src=${Public.Scripts.Blob_To_Img_URL(Icon_Blob)}/>`}
                </div>
                <span>${Item.Title}</span>
              </li>
            <//>
          `
        })}
      </ul>
      <div className="_Section">
        <${App_About_Detail}
          Props=${Props}
          About_ID=${selectedAboutId}
        />
      </div>
    <//>
  `);
};