/*██████████████████████████████████████████████████████████
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░||░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░|-|░░░░░░░░||░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░| |░░░░░░░░||░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░ /   _| |_   \ ░░//░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|  / ,| |. \  |_//░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░| ( ( '-' ) ) |-'░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|  \ `'"'' /  |░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|   `-----'   ;░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|\___________/|░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░|             ;░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░ \___________/░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
████████████████████████████████████████████████████████████
*/
//-----------------------------------------------------------------------------

  import { TREE } from '../TREE.js';

  import { MODE_PLAY } from './PLAY/MODE_PLAY.js';
  import { MODE_EDIT } from './EDIT/MODE_EDIT.js';
  
//-----------------------------------------------------------------------------
//▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  export class TREE_MODE extends TREE { constructor (MAIA) { super(MAIA);
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.ACTIVE = undefined;
  // this.Active = '';
  // this.MODE = undefined;

}
//----------------------------------------------------------------------------------------
  Init (Mode) {
//----------------------------------------------------------------------------------------

  if(this.ACTIVE) { this.ACTIVE.Reset(); }

  if(Mode === 'PLAY') { this.ACTIVE = new MODE_PLAY(this.MAIA); }
  else
  if(Mode === 'EDIT') { this.ACTIVE = new MODE_EDIT(this.MAIA); }
  else {
    console.error(`Mode ${Mode} not defined, defaulting to EDIT.`);
    this.ACTIVE = new MODE_EDIT(this.MAIA) // By default
  }
  
  this.ACTIVE.Init();

}
//----------------------------------------------------------------------------------------
  Toggle () {
//----------------------------------------------------------------------------------------

  if(this.ACTIVE.ID === 'PLAY') { this.Init('EDIT'); }
  else if(this.ACTIVE.ID === 'EDIT') { this.Init('PLAY'); }

}
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

  // 🎮 Toggle: Select
  if (Public.TOKEN.Power === 'ADMIN') {
    this.MAIA.TREE.CLIENT.INPUT.Read_Input(["Gamepad", "Select"], () => {
      this.Toggle();
    }, true, "Mode_Edit_Gamepad_Select");
  }

  // Pass only when conditions true
  if(this.ACTIVE.Loaded === false) { 
    
    if(this.ACTIVE.Load()) {
      this.MAIA.TREE.CAMERA.Active = true;
      this.ACTIVE.Loaded = true;
    }

    return; 

  }

  this.ACTIVE.Run();

}
//----------------------------------------------------------------------------------------
  Reset () {
//----------------------------------------------------------------------------------------

  this.ACTIVE.Reset();
  // this.ACTIVE = null;

}
//----------------------------------------------------------------------------------------
}