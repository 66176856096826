import { Styled } from './Style/Style_App_Login.js';

export const App_Login = ({ Props }) => {

/*█████████████████████████████████   📚 DEPS   ████████████████████████████████████*/

  let URI = `${Public.ENV.URL}/Apps/Login`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const history = RouterDOM.useHistory();

  // Refs
  const theForm = useRef(null);

  const [ID, SetID] = useState(null);

/*████████████████████████████████   🦉 SCRIPTS   ██████████████████████████████████*/

  const Login = async (e) => {

    e.preventDefault();
    const sendForm = new FormData(theForm.current);
    await fetch(`${Public.ENV.URL}/user/access/login`, { 
      method: 'POST',
      body: sendForm,
    })
    .then(fetch => fetch.json())
    .then(res => {
      
      Public.Scripts.Notify.Send({
        Title: "Sesión iniciada", 
        Description: `¡Hola de nuevo!`, 
        Image: "User", 
        Time: 3000
      });

      console.log(res);
      Public.TOKEN = Public.Token.Get('ACCESS');
      SetID(Public.TOKEN.User_ID);

      history.push('/user');

    });

  }
   
/*█████████████████████████████████   💻 RENDER   ███████████████████████████████████*/

return (html`
<${Style}>
<form className="Form" ref=${theForm} 
onSubmit=${(e)=> { Public.Scripts.Validation.Form(e, () => Login(e)); }}>

  <div className="Container_Input">
    <!--<div className="Input_Label">Nombre</div>-->
    <div className="Input">
      <i className="icofont-user icofont-1x" />
      <input type="text" name="ID" placeholder="Nombre" tabIndex="1" autoFocus data-validation="required" />
    </div>
  </div>
  <div className="Container_Input">
    <div className="Input">
      <i className="icofont-ui-password icofont-1x" />
      <input type="password" name="Password" placeholder="Contraseña" tabIndex="2" data-validation="email" data-validation="required"/>
    </div>
  </div>
  <div className="Container_Input">
    <input className="Input_Button" type="submit" value="Entrar" tabIndex="3" />
  </div>

  <div className="_Options">
    <!--<${RouterDOM.Link} to="/sign" className="_Or_Sign">¿Quieres crear una cuenta?<//>-->
    <${RouterDOM.Link} to="/restore" className="_Or_Sign">¿Has olvidado la contraseña?<//>
  </div>

</form>
<//>
`);
  
};