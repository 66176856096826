export const Styled = (styled, Styles, URI) => {

const Style = styled.div`
width: 100%;
height: 100%;
display: flex;

._Container {
width: 100%;
// height: 100%;
overflow: hidden;
border-radius: 7px;
display: flex;
flex-direction: row;
// box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

`;

return Style;

}