// import { Board_Preview } from '../../Preview/Board_Preview.js';
// import { Chip } from "../../../../../Core/API/MODEL/Chip/Chip.js";
import { Items_Chips_List } from "./List/Items_Chips_List.js";
import { Items_Chips_Detail } from "./Detail/Items_Chips_Detail.js";
import { Style } from './Style/Style_Items_Chips.js';

export const Items_Chips = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//████████████████████████████████████████████████████████████████████████████████████

  const thePanel = useRef(null);

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled} ref=${thePanel}>

  <${RouterDOM.Route} exact path='/items/chips'>
    <${Items_Chips_List} Props=${Props} />
  <//>

  <${RouterDOM.Route} path=${[
    "/items/chips/:chip_id",
    "/items/chips/:chip_id/about",
    "/items/chips/:chip_id/about/:about_id",
  ]}>
    <${Items_Chips_Detail} Props=${Props} />
  <//>

<//>
`);
}