//----------------------------------------------------------------------------------------

import { Style } from './Style/Style_Logo.js';

//----------------------------------------------------------------------------------------
export const App_Logo = ({ Props }) => {
//----------------------------------------------------------------------------------------

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

const { useEffect, useRef } = Public.Libs.React;
const html = Public.Libs.html;
const Styled = Public.Styles.Styled(Style, {});

// State
const theLogo = useRef(null);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Effects
//----------------------------------------------------------------------------------------

  useEffect(() => {
    let rotationDegree = 0;

    const handleMouseEnter = () => {
      rotationDegree += 1080; // Añade 1440 grados con cada hover
      theLogo.current.style.transform = `rotate(${rotationDegree}deg)`;
    };

    const logoElem = theLogo.current;
    logoElem.addEventListener('mouseenter', handleMouseEnter);

    return () => {
      logoElem.removeEventListener('mouseenter', handleMouseEnter);
    };
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled} className="Sidebar_Item"
onClick=${() => Public.Scripts.Toggle_Fullscreen() }>
  <img src="${Public.ENV.URL}/Public/Media/Icons/Logo/Logo.png" ref=${theLogo} />
<//>  
`);
//----------------------------------------------------------------------------------------
}